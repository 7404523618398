import React, { useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { useEffect } from "react";

export default function GoUpWidget() {

  const [show, setShow] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setShow(true) 
    }  
    else if (scrolled <= 300){ 
      setShow(false) 
    } 
  }; 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  }; 
  
  useEffect(()=>{
    window.addEventListener('scroll', toggleVisible); 
  }, [window.scroll])

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div 
        onClick={()=>scrollToTop()}
        className="fixed z-40 text-white clickable flex items-center justify-center rounded-full bg-yellow-600 shadow-lg right-5 bottom-5 w-14 h-14">
        <ChevronUpIcon className="w-10" />
      </div>
    </Transition>
  );
}
