import Container from "./container";
import _ from "lodash";
const heroImg = "./img/bg-eth-gray.png";

export default function Hero() {

  const debouncedDapp = _.debounce(()=>document.getElementById("dapp-component").click(),100);

  return (
    <>
      <Container className="flex flex-wrap ">
        <div className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl mb-8">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-white lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight">
              The $REFUND Project
            </h1>
            <p className="py-5 text-xl leading-normal text-gray-300 lg:text-xl xl:text-2xl">
              A retribution for all the people that used the Blockchain network.
              It doesn't matter if you buy, sell, trade, hold, or even mine.
              If you did any transaction, connect your wallet and receive a $REFUND token reward according to your contributions
            </p>

            <div className="flex flex-col items-start space-x-3 space-y-3 sm:space-y-0 sm:items-center sm:flex-row">
              <button
                onClick={debouncedDapp}
                target="_blank"
                rel="noopener"
                className="px-8 py-4 text-lg font-medium text-center text-white bg-yellow-600 rounded-md ">
                Connect your wallet
              </button>
              <span className="flex items-center space-x-2 text-gray-400">
                And receive the rewards!<br />(Gas fees may apply)
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="hidden lg:block">
            <img
              src={heroImg}
              width="616"
              height="617"
              alt="Hero Illustration"
              layout="intrinsic"
              loading="eager"
              placeholder="blur"
            />
          </div>
        </div>
      </Container>
      <Container id={'premise'}>
        <div className="flex flex-col justify-center">
          <div className="text-xl text-center text-white">
            Already awarded <span className="text-yellow-600">200'000+</span> users
            worldwide
          </div>

          <div className="text-2xl text-center text-white mt-5">
            Some supported Blockchains:
          </div>

          <div className="flex flex-wrap justify-center gap-5 mt-10 md:justify-around">
            <div className="pt-2 text-gray-400">
              <EthereumLogo />
            </div>
            <div className="text-gray-400">
              <BaseLogo />
            </div>
            <div className="text-gray-400">
              <PolygonLogo />
            </div>
            <div className="pt-1 text-gray-400">
              <ArbitrumLogo />
            </div>
            <div className="pt-2 text-gray-400">
              <LineaLogo />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

function BaseLogo() {
  return (
    <>
      <div className="flex items-center mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11 ">
          <img width='600' height='600' className="rounded-2xl duration-400 ease-in-out" src="/img/brands/base_Logo.jpg" style={{ color: "transparent" }} />
        </div>
        <div className='flex items-center justify-center'>
          <h4 className="text-xl font-medium text-gray-400">
            Base
          </h4>
        </div>
      </div>
    </>
  )
}

function EthereumLogo() {
  return (
    <>
      <div className="flex items-center mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11 ">
          <img width='600' height='600' className="rounded-2xl duration-400 ease-in-out" src="/img/brands/ethereum_Logo.jpg" style={{ color: "transparent" }} />
        </div>
        <div className='flex items-center justify-center'>
          <h4 className="text-xl font-medium text-gray-400">
            Ethereum
          </h4>
        </div>
      </div>
    </>
  )
}

function PolygonLogo() {
  return (
    <>
      <div className="flex items-center mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11 ">
          <img width='600' height='600' className="rounded-2xl duration-400 ease-in-out" src="/img/brands/polygon_Logo.jpg" style={{ color: "transparent" }} />
        </div>
        <div className='flex items-center justify-center'>
          <h4 className="text-xl font-medium text-gray-400">
            Polygon
          </h4>
        </div>
      </div>
    </>
  )
}

function ArbitrumLogo() {
  return (
    <>
      <div className="flex items-center mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11 ">
          <img width='600' height='600' className="rounded-2xl duration-400 ease-in-out" src="/img/brands/arbitrum_one_Logo.png" style={{ color: "transparent" }} />
        </div>
        <div className='flex items-center justify-center'>
          <h4 className="text-xl font-medium text-gray-400">
            Arbitrum
          </h4>
        </div>
      </div>
    </>
  )
}

function LineaLogo() {
  return (
    <>
      <div className="flex items-center mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11 ">
          <img width='600' height='600' className=" rounded-2xl duration-400 ease-in-out" src="/img/brands/linea_Logo.jpg" style={{ color: "transparent" }} />
        </div>
        <div className='flex items-center justify-center'>
          <h4 className="text-xl font-medium text-gray-400">
            Linea
          </h4>
        </div>
      </div>
    </>
  )
}