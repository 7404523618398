import React from "react";
import Container from "./container";
import _ from "lodash";

export default function Cta() {

  const debouncedDapp = _.debounce(()=>document.getElementById("dapp-component").click(),100);

  return (
    <>
    <Container>
      <div className="flex flex-wrap items-center justify-between w-full max-w-4xl gap-5 mx-auto text-white bg-yellow-600 px-7 py-7 lg:px-12 lg:py-12 lg:flex-nowrap rounded-xl">
        <div className="flex-grow text-center lg:text-left">
          <h2 className="text-2xl font-medium lg:text-3xl">
            Connect and claim your reward!
          </h2>
          <p className="mt-2 italic font-small text-gray-300 text-opacity-90 lg:text-l">
            (Gas fees may apply)
          </p>
        </div>
        <div className="flex-shrink-0 w-full text-center lg:w-auto">
          <button
            onClick={debouncedDapp}
            target="_blank"
            rel="noopener"
            className="inline-block py-3 mx-auto text-xl font-medium text-center text-yellow-600 bg-white rounded-md px-7 lg:px-10 lg:py-5 ">
            Connect your Wallet
          </button>
        </div>
      </div>
    </Container>
    </>
  );
}
