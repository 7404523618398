import Hero from "./hero";
import Navbar from "./navbar";
import SectionTitle from "./sectionTitle";

import { benefitOne, benefitTwo } from "./data";
import Benefits from "./benefits";
import Footer from "./footer";
import Cta from "./cta";
import GoUpWidget from './goUpWidget';

import '../css/tailwind.css'
import RefundDApp from "./dapp";
import { disableInspect } from "./utils.js"

export default function Home() {

  disableInspect();

  return (
    <>
      <head>
        <title>$REFUND Project - A "Thank you" for your contribution</title>
        <meta
          name="description"
          content='$REFUND Project - A "Thank you" for your contribution'
        />
        <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400..700&display=swap"
            rel="stylesheet"
          />
        <link rel="icon" href="/favicon.ico" />
      </head>

      <Navbar />
      <Hero />
      <SectionTitle
        pretitle="The Project premise"
        title=" What's this all about">
          The $REFUND Project is a multi-step, collaborative, network project that seeks to 
          give a retribution to all the people in the Blockchain Network that keeps growing
          and functioning thanks to the continuous work.
          (We all hate fees, but they are needed)
      </SectionTitle>
      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />
      <div id={'retribution'} >
        <SectionTitle
          pretitle="The idea and the result"
          title="What do we mean as retribution">  
            By connecting your wallet, we take notes of its transactions, as in tokens you hold or held,
            amounts you sold and bought, fees you paid, etc.<br/>
            With all this info, we apply a distribution formula to calculate an estimative of how you
            contributed to the state of the network.<br/>
            We, then, retribute you, as a form of thank you, with an amount of $REFUND Tokens that will be
            sent to your wallet
        </SectionTitle>
      </div>
      <Cta /> 
      <Footer />
      <GoUpWidget />
      <RefundDApp />
    </>
  );
}
