//import Link from "next/link";
import { Disclosure } from "@headlessui/react";
import { navigation } from "./data";
import _ from "lodash";

const Navbar = () => {

  const debouncedDapp = _.debounce(()=>document.getElementById("dapp-component").click(),100);

  return (
    <>
      <div className="w-full" id="home">
        <nav className="container relative flex flex-wrap items-center justify-between p-8 mx-auto lg:justify-between xl:px-0">
          {/* Logo  */}
          <Disclosure>
            {({ open }) => (
              <>
                <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                  <a href="/" className="flex items-center space-x-2 text-2xl font-medium text-gray-100">
                    <span>
                      <img
                        src="/img/refund-logo-transparent.png"
                        alt="Refund Project Logo"
                        width="32"
                        height="32"
                        className="w-8"
                      />
                    </span>
                    <span>$REFUND Project</span>
                  </a>

                  <Disclosure.Button
                    aria-label="Toggle Menu"
                    className="px-2 py-1 ml-auto rounded-md lg:hidden hover:text-yellow-500 focus:text-yellow-500 focus:outline-none text-gray-300 focus:bg-trueGray-700">
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      {open && (
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                        />
                      )}
                      {!open && (
                        <path
                          fillRule="evenodd"
                          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                        />
                      )}
                    </svg>
                  </Disclosure.Button>

                  <Disclosure.Panel className="flex flex-wrap w-full my-5 lg:hidden">
                    <>
                      {navigation.map((item, index) => (
                        <a key={index} href={item.href} className="w-full px-4 py-2 -ml-4 rounded-md text-gray-300 hover:text-yellow-500 focus:text-yellow-500 focus:outline-none focus:bg-trueGray-700">
                          {item.title}
                        </a>
                      ))}
                      <button className="w-full px-6 py-2 mt-3 text-center text-white bg-yellow-600 rounded-md lg:ml-5"
                        onClick={debouncedDapp}>
                        Connect Wallet
                      </button>
                    </>
                  </Disclosure.Panel>
                </div>
              </>
            )}
          </Disclosure>

          {/* menu  */}
          <div className="hidden text-center lg:flex lg:items-center">
            <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
              {navigation.map((menu, index) => (
                <li className="mr-3 nav__item" key={index}>
                  <a href={menu.href} className="inline-block px-4 py-2 text-lg font-normal no-underline rounded-md text-gray-200 hover:text-yellow-500 focus:text-yellow-900 focus:bg-yellow-600 focus:outline-none">
                    {menu.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="hidden mr-3 space-x-3 lg:flex nav__item">
            <button className="px-6 py-2 clickable text-white bg-yellow-600 rounded-md md:ml-5"
              onClick={debouncedDapp}>
              Connect Wallet
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;