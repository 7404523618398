import {
  UserGroupIcon,
  PresentationChartLineIcon,
  SpeakerphoneIcon,
  ViewGridAddIcon,
  ChipIcon,
  GlobeAltIcon,
} from "@heroicons/react/outline";

const benefitOneImg = "./img/bg-eth-gray3.png";
const benefitTwoImg = "./img/bg-eth-gray2.png";

const benefitOne = {
  title: "Network Development",
  desc: "Thanks to the continuous work and development on the Network.",
  image: benefitOneImg,
  bullets: [
    {
      title: "Blockchain community hard work",
      desc: "All the people keeps collaborating on the network.",
      icon: <UserGroupIcon />,
    },
    {
      title: "Web3 and DApps",
      desc: "Web3 is still growing to be the future.",
      icon: <PresentationChartLineIcon />,
    },
    {
      title: "Community and Marketing",
      desc: "Even if you don't work directly on the blockchain, you do your bit",
      icon: <SpeakerphoneIcon />,
    },
  ],
};

const benefitTwo = {
  title: "Network Popularity",
  desc: "The blockchain is all what it is, because of you.",
  image: benefitTwoImg,
  bullets: [
    {
      title: "Transactions",
      desc: "You may buy, sell, or transfer. Each transaction make a story.",
      icon: <ViewGridAddIcon />,
    },
    {
      title: "Mining",
      desc: "Miners spend time and money to keep the wheels going round and round.",
      icon: <ChipIcon />,
    },
    {
      title: "Tokens and NFTs",
      desc: "The most common representation of currency in the network",
      icon: <GlobeAltIcon />,
    },
  ],
};

const tokenInfo = {
  ethscan: 'https://etherscan.io/token/0x955d5c14C8D4944dA1Ea7836bd44D54a8eC35Ba1',
  twitter: 'https://twitter.com/RefundCoinETH',
  coinmarketcap: 'https://coinmarketcap.com/currencies/refund/',
  coingecko: 'https://www.coingecko.com/en/coins/refund/',

};

const navigation = [
  { title: 'Home', href: '#home' },
  { title: 'Premise', href: '#premise' },
  { title: 'Applicants', href: '#applicants' },
  { title: 'Retribution', href: '#retribution' }];

export { benefitOne, benefitTwo, tokenInfo, navigation };
