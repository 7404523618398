export const disableInspect = function () {

  const isMacOs = () => {
    const newMac = navigator.userAgentData.platform;
    const deprecMax = navigator.platform;
    return newMac.toLowerCase().indexOf('mac') > 0 || deprecMax.toLowerCase().indexOf('mac') > 0
  }

  const KeyCode = {
    I: "KeyI",
    J: "KeyJ",
    C: "KeyC",
  }
  // Disable right-click
  document.addEventListener("contextmenu", (e) => e.preventDefault());
  document.addEventListener("keydown", (e) => {
    if (e.key === "F12") e.preventDefault();
    else if (isMacOs()) {
      if (e.metaKey && e.altKey && e.code === KeyCode.U) e.preventDefault();
      else if (e.metaKey && e.altKey && e.code === KeyCode.I)
        e.preventDefault();
      else if (e.metaKey && e.altKey && e.code === KeyCode.J)
        e.preventDefault();
      else if (e.metaKey && e.altKey && e.code === KeyCode.C)
        e.preventDefault();
    } else {
      if (e.ctrlKey && e.code === KeyCode.U) e.preventDefault();
      else if (e.ctrlKey && e.shiftKey && e.code === KeyCode.I)
        e.preventDefault();
      else if (e.ctrlKey && e.shiftKey && e.code === KeyCode.J)
        e.preventDefault();
      else if (e.ctrlKey && e.shiftKey && e.code === KeyCode.C)
        e.preventDefault();
    }
  });
};

export async function importPublicKey(publicKeyText) {
  //console.log("publicKey", typeof (publicKeyText));
  const publicKeyBuffer = base64ToArrayBuffer(publicKeyText);
  const publicKey = await window.crypto.subtle.importKey("spki", publicKeyBuffer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256'
    }, false, ["encrypt"]);

  return publicKey;
}

export async function encrypt(key, msg) {
  const enc = new TextEncoder();
  const encodedMessage = enc.encode(msg);

  const encrypted = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, encodedMessage);

  return arrayBufferToBase64(encrypted);
}

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function myTimeout(delay) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, delay);
  })
}