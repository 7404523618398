const chains = [
    {
        id: "sepolia",
        chainId: 11155111,
        name: "Sepolia",
        currency: "ETH",
        explorerUrl: "https://sepolia.etherscan.io",
        rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
    },
    {
        "id": "linea",
        "chain_identifier": 59144,
        "name": "Linea",
        "shortname": "",
        "native_coin_id": "ethereum",
        chainId: 59144,
        currency: 'ETH',
        explorerUrl: 'https://lineascan.build/',
        rpcUrl: 'https://rpc.linea.build',
    },
    {
        "id": "ethereum",
        "chain_identifier": 1,
        "name": "Ethereum",
        "shortname": "Ethereum",
        "native_coin_id": "ethereum",
        chainId: 1,
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://rpc.lokibuilder.xyz/wallet',
    },
    {
        "id": "polygon-zkevm",
        "chain_identifier": 1101,
        "name": "Polygon zkEVM",
        "shortname": "",
        "native_coin_id": "ethereum",
        chainId: 1101,
        currency: 'POL',
        explorerUrl: 'https://zkevm.polygonscan.com',
        rpcUrl: 'https://endpoints.omniatech.io/v1/polygon-zkevm/mainnet/public',
    },
    {
        "id": "polygon-pos",
        "chain_identifier": 137,
        "name": "Polygon POS",
        "shortname": "MATIC",
        "native_coin_id": "matic-network",
        chainId: 137,
        currency: 'POL',
        explorerUrl: 'https://polygonscan.com/',
        rpcUrl: 'https://polygon-bor-rpc.publicnode.com',
    },
    {
        "id": "arbitrum-one",
        "chain_identifier": 42161,
        "shortname": "Arbitrum",
        "native_coin_id": "ethereum",
        chainId: 42161,
        name: 'Arbitrum',
        currency: 'ETH',
        explorerUrl: 'https://arbiscan.io',
        rpcUrl: 'https://1rpc.io/arb',
    },
    {
        "id": "arbitrum-nova",
        "chain_identifier": 42170,
        "shortname": "",
        "native_coin_id": "ethereum",
        chainId: 42170,
        name: 'Arbitrum',
        currency: 'ETH',
        explorerUrl: 'https://nova.arbiscan.io/',
        rpcUrl: 'https://arbitrum-nova-rpc.publicnode.com',
    },
    {
        "id": "base",
        "chain_identifier": 8453,
        "name": "Base",
        "shortname": "",
        "native_coin_id": "ethereum",
        chainId: 8453,
        currency: 'ETH',
        explorerUrl: 'https://basescan.org/',
        rpcUrl: 'https://base.llamarpc.com',
    }
]

module.exports = { chains };